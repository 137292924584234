/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"

const componentStyle = css`
    button.search,
    button.add {
        margin-top: 24px !important;
    }
`
class AddAgency extends React.Component{

    state = {
        name: "",
        typeOfEntity: 0,
        admittedProducerCode: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        mainPhone: "",
        website: "",
        commission: 20,
        error: "",
        validating: false
    }

    componentDidMount(){
        window.scrollTo(0,0)

        const { brokerCompaniesContainer } = this.props

        if(!brokerCompaniesContainer.state.entityTypes.length){
            history.push("/agencies-and-contacts")
        }

        if(brokerCompaniesContainer.state.editAgency){            
            const agency = brokerCompaniesContainer.state.editAgency

            this.setState({
                id: agency.id,
                name: agency.name,
                typeOfEntity: agency.typeOfEntity,
                admittedProducerCode: agency.admittedProducerCode,
                addressLine1: agency.addressLine1,
                addressLine2: agency.addressLine2,
                city: agency.city,
                state: agency.state,
                postalCode: agency.postalCode,
                mainPhone: agency.mainPhone,
                website: agency.website,
                commission: (agency.commission || agency.commission === 0) ? agency.commission : 20
            })
        }        
    }

    updateValue = (e)=>{
        e = e.currentTarget

        this.setState({
            [e.name]: e.value
        })

        if(this.state.validating){
            this.validateForm()
        }
    }

    addAgency = async ()=>{
        if(!await this.validateForm()){
            return
        }
        const { brokerCompaniesContainer } = this.props

        let result

        if(brokerCompaniesContainer.state.editAgency){
            result = await brokerCompaniesContainer.updateBrokerCompany(this.state)
        }else{
            result = await brokerCompaniesContainer.addBrokerCompany(this.state)
        }
        
        if(!result?.succeeded){
            this.setState({
                error: result?.errorMessage || "Error",
            })
        }else{
            await brokerCompaniesContainer.getBrokerCompany(result.id, true)
            //await brokerCompaniesContainer.searchBrokerCompanies(this.state.name, 1, getRecordsPerPage(), "name", false)
            history.push("/agencies-and-contacts")
        }
    }

    validateForm = async ()=>{

        this.setState({
            validating: true
        })

        const notRequired = [
            "id",
            "typeOfEntity",
            "admittedProducerCode",
            "addressLine2",
            "error",
            "validating"
        ]

        let valid = true
        let error = "Please complete all required fields"

        for (const [key, value] of Object.entries(this.state)) {
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }

        if(this.state.typeOfEntity === ""){
            valid = false
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }        
    }

    cancel = async ()=>{
        history.push("/agencies-and-contacts")
    }

    render(){
        const { brokerCompaniesContainer } = this.props

        return(
            <>
            <div css={componentStyle}>
                <div className="row margin-top">
                    <div className="col">
                        <h2>{brokerCompaniesContainer.state.editAgency ? "Edit" : "Add"} Agency</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name"
                                className={(this.state.validating && !this.state.name) 
                                ? "required" : ""}>
                            Agency Name:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="name" 
                                name="name"
                                value={this.state.name}
                                onChange={this.updateValue}
                                required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="typeOfEntity"
                                className={(this.state.validating && !this.state.typeOfEntity) 
                                            ? "required" : ""}>
                            Type of Entity:<span className="required">*</span>
                        </label>
                        <select className="form-control form-control-sm" 
                                id="typeOfEntity"
                                name="typeOfEntity"
                                value={this.state.typeOfEntity}
                                onChange={this.updateValue}
                                required>                            
                            <option value="">--- Select ---</option>
                            {brokerCompaniesContainer.state.entityTypes.map(entityType =>(
                                <option key={entityType.id}
                                        value={entityType.id}>
                                    {entityType.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressLine1"
                                className={(this.state.validating && !this.state.addressLine1) 
                                ? "required" : ""}>
                            Company Address Line 1:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="addressLine1" 
                                name="addressLine1"
                                value={this.state.addressLine1}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressLine2">Company Address Line 2:</label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="addressLine2" 
                                name="addressLine2"
                                value={this.state.addressLine2}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city"
                                className={(this.state.validating && !this.state.city) 
                                ? "required" : ""}>
                            Company City:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="city" 
                                name="city"
                                value={this.state.city}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state"
                                className={(this.state.validating && !this.state.state) 
                                ? "required" : ""}>
                            Company State:<span className="required">*</span>
                        </label>
                        <select className="form-control form-control-sm" 
                                id="state"
                                name="state"
                                value={this.state.state}
                                onChange={this.updateValue}>                            
                            <option value="">--- Select Company State ---</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="VI">Virgin Islands</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="postalCode"
                                className={(this.state.validating && !this.state.postalCode) 
                                ? "required" : ""}>
                            Postal Code:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="postalCode" 
                                name="postalCode"
                                value={this.state.postalCode}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mainPhone"
                                className={(this.state.validating && !this.state.mainPhone) 
                                ? "required" : ""}>
                            Main Company Phone Number:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="mainPhone" 
                                name="mainPhone"
                                value={this.state.mainPhone}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="website"
                                className={(this.state.validating && !this.state.website) 
                                ? "required" : ""}>
                            Company Web Address:<span className="required">*</span>
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="website" 
                                name="website"
                                value={this.state.website}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="commission"
                                className={(this.state.validating && !this.state.commission) 
                                ? "required" : ""}>
                            Commission:<span className="required">*</span>
                        </label>
                        <input type="number" 
                                className="form-control form-control-sm" 
                                id="commission" 
                                name="commission"
                                value={this.state.commission}
                                onChange={this.updateValue} />
                    </div>
                </div>
            </div>

            {this.state.error !== "" &&
                <div className="row">
                    <div className="col-sm-6">
                        <p className="error">{this.state.error}</p>
                    </div>
                </div>
            }

            <div className="row button-group">
                <div className="col-sm-6">
                    <button className="btn btn-sm btn-primary float-right"
                            onClick={this.addAgency}
                            disabled={brokerCompaniesContainer.state.isLoading}>Submit</button>
                     <button className="btn btn-sm btn-secondary float-right"
                            onClick={this.cancel}
                            disabled={brokerCompaniesContainer.state.isLoading}>Cancel</button>
                    
                </div>
            </div>
            </>
        )
    }   
}

export default AddAgency