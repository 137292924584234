/** @jsxImportSource @emotion/react */
import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import { Subscribe } from "unstated"
import { css } from "@emotion/react/macro"
import history from "../components/History"
import AuthenticationContainer from "../state/AuthenticationContainer"
import ApplicationsContainer from "../state/ApplicationsContainer"
import BrokerCompaniesContainer from "../state/BrokerCompaniesContainer"
import AccountsContainer from "../state/AccountsContainer"
import RegistrationsContainer from "../state/RegistrationsContainer"
import PolicyNumbersContainer from "../state/PolicyNumbersContainer"
import EndorsementsContainer from "../state/EndorsementsContainer"
import Header from "./Header"
import Login from "./Authentication/Login"
import Logout from "./Authentication/Logout"
import TwoFactorLogin from "./Authentication/TwoFactorLogin"
import ForgotPassword from "./Authentication/ForgotPassword"
import ResetPassword from "./Authentication/ResetPassword"
import Dashboard from "./Dashboard"
import Warning from "../components/Warning"
import ReportingContainer from "../state/ReportingContainer"

const componentStyle = css`
    margin-bottom: 20px;
    margin-top: 130px;

    h1{
        font-size: 24px;
        line-height: 24px;
        margin: 0;
    }

    h2{
        font-size: 22px;
        line-height: 22px;
        margin: 0;
        margin-bottom: 15px;
    }

    h3{
        font-size: 18px;
        line-height: 18px;
        margin: 0;
        color: #7694aa;
    }

    a{
        color: #035489;
    }

    p, a, th, td, label, li{
        font-size: 14px;
    }

    p.error{
        color: #cc0000;
        float: right;
        clear: both;
        margin: 0;
        text-align: right;
    }

    p.directions,
    p.info{
        margin-top: 10px;
    }

    span.required{
        color: #cc0000;
        margin-left: 5px;
    }

    span.required-example{
        color: #cc0000;
    }

    label.required{
        color: #cc0000;
    }

    select.required{
        border-color: #cc0000;
        color: #cc0000;
    }

    input.required,
    textarea.required{
        border-color: #cc0000;
    }

    input.required::placeholder,
    textarea.required::placeholder{
        color: #cc0000;
    }

    .margin-top{
        margin-top: 15px;
    }

    .margin-top-none{
        margin-top: 0px;
    }

    .margin-bottom{
        margin-bottom: 15px;
    }

    table{
        margin-top: 10px;
        border-bottom: solid 1px #cc0000;
    }

    .sortable-table{
        table-layout: fixed !important;
        border-bottom: none;
    }

    .sortable-body{
        display: table;
        table-layout: fixed;
    }

    .sortable-table td.action,
    .sortable-table th.action{
        width: 50px !important;
        text-align: center;
    }

    .sortable-table-border-bottom-fix{
        width: 100%;
        height: 1px;
        display: block;
        background: #cc0000;
        margin-bottom: 20px;
        margin-top: -18px;
    }   
    
    th{
        border-top: solid 2px #cc0000;
    }

    tr{
        border-color: lightgrey;
    }

    td.action{
        width: 50px;
        text-align: center;
    }

    .button-group{
        margin-top: 20px;
    }

    .button-group button{
        margin-left: 20px;
    }

    .btn-primary{
        background-color: #7694aa;
        border-color: #7694aa; 
    }

    button:disabled{
        opacity: 0.5;
    }

    button.anchor{
        background: none;
        border: none;
        padding: 0;
        color: #035489;
        outline: none;
    }

    button.anchor:hover{
        text-decoration: underline;
    }

    .form-group{
        margin-bottom: 10px;
    }

    //override bootstrap custom input to small
    .fileUpload{
        margin-bottom: 10px;
    }

    .custom-file{
        width: calc(100% - 75px);
        margin-right: 10px;
        height: 31px !important;
        line-height: normal;
    }

    .custom-file-input,
    .custom-file-input:after{
        height: 31px !important;
        padding: 4px 8px !important;
    }

    .col-form-label-sm{    
        height: 31px !important;
        padding: 4px 8px !important;
       
    }

    .col-form-label-sm:after{
        border-bottom: solid 1px rgb(206, 212, 218);
        height: 30px !important;
        padding: 4px 8px !important;
    }

    label{
        margin-bottom: 4px;
        display: block;
    }

    .underlined{
        text-decoration: underline;
    }

    .react-datepicker__current-month {
        font-size: 16px;
    }
`
class Main extends React.Component{ 

    componentDidMount(){
        console.log("%c Great American Environmental: Storage Tank Policy 🇺🇸 ", "background: #cc0000; color: #fff")
        console.log("%c 🗓 22-04-08 " , "background: #000; color: #fff")
    }
        
    render(){

        return(         
            <div className="container-fluid" css={componentStyle}>                
                <Subscribe to={[AuthenticationContainer, 
                                ApplicationsContainer,
                                BrokerCompaniesContainer, 
                                AccountsContainer,
                                RegistrationsContainer,
                                PolicyNumbersContainer,
                                EndorsementsContainer,
                                ReportingContainer]}>
                            {(authenticationContainer, 
                              applicationsContainer,
                              brokerCompaniesContainer, 
                              accountsContainer,
                              registrationsContainer,
                              policyNumbersContainer,                              
                              endorsementsContainer,
                              reportingContainer) =>

                    <>
                    <Router history={history}>
                        <Header authenticationContainer={authenticationContainer}
                                applicationsContainer={applicationsContainer}
                                brokerCompaniesContainer={brokerCompaniesContainer}
                                accountsContainer={accountsContainer}
                                registrationsContainer={registrationsContainer}
                                policyNumbersContainer={policyNumbersContainer}
                                endorsementsContainer={endorsementsContainer} 
                                reportingContainer={reportingContainer} />

                        <Switch>
                            <Route path="/login" 
                                    exact
                                    render={()=> 
                                    <Login authenticationContainer={authenticationContainer} />} />
                            <Route path="/logout" 
                                    exact
                                    render={()=> 
                                    <Logout authenticationContainer={authenticationContainer} />} />
                            <Route path="/two-factor-login" 
                                    exact
                                    render={()=> 
                                    <TwoFactorLogin authenticationContainer={authenticationContainer} />} />
                            <Route path="/forgot-password" 
                                    exact
                                    render={()=> 
                                    <ForgotPassword authenticationContainer={authenticationContainer} />} />
                            <Route path="/reset-password/*" 
                                    exact
                                    render={()=> 
                                    <ResetPassword authenticationContainer={authenticationContainer} />} />
                            <Route path="/*" 
                                    exact
                                    render={()=> 
                                    <Dashboard authenticationContainer={authenticationContainer} />} />
                        </Switch>

                    </Router>
                    
                    {((authenticationContainer.state.warnings.length > 0) || 
                      (applicationsContainer.state.warnings.length > 0) ||
                      (brokerCompaniesContainer.state.warnings.length > 0) || 
                      (accountsContainer.state.warnings.length > 0) ||
                      (registrationsContainer.state.warnings.length > 0) || 
                      (reportingContainer.state.warnings.length > 0)) &&                    
                        <Warning warnings={
                                (authenticationContainer.state.warnings.length > 0) ? authenticationContainer.state.warnings :
                                (applicationsContainer.state.warnings.length > 0) ? applicationsContainer.state.warnings :
                                (brokerCompaniesContainer.state.warnings.length > 0) ? brokerCompaniesContainer.state.warnings :
                                (accountsContainer.state.warnings.length > 0) ? accountsContainer.state.warnings :
                                (reportingContainer.state.warnings.length > 0) ? reportingContainer.state.warnings :
                                registrationsContainer.state.warnings
                                }
                                warningHeader={
                                    (authenticationContainer.state.warnings.length > 0) ? authenticationContainer.state.warningHeader :
                                    (applicationsContainer.state.warnings.length > 0) ? applicationsContainer.state.warningHeader :
                                    (brokerCompaniesContainer.state.warnings.length > 0) ? brokerCompaniesContainer.state.warningHeader :
                                    (accountsContainer.state.warnings.length > 0) ? accountsContainer.state.warningHeader :
                                    (reportingContainer.state.warnings.length > 0) ? reportingContainer.state.warningHeader :
                                    registrationsContainer.state.warningHeader
                                }
                                warningFooter={
                                    (authenticationContainer.state.warnings.length > 0) ? authenticationContainer.state.warningFooter :
                                    (applicationsContainer.state.warnings.length > 0) ? applicationsContainer.state.warningFooter :
                                    (brokerCompaniesContainer.state.warnings.length > 0) ? brokerCompaniesContainer.state.warningFooter :
                                    (accountsContainer.state.warnings.length > 0) ? accountsContainer.state.warningFooter :
                                    (reportingContainer.state.warnings.length > 0) ? reportingContainer.state.warningFooter :
                                    registrationsContainer.state.warningFooter
                                }
                                 close={()=>{
                                    authenticationContainer.setState({
                                        warnings: []
                                    })
                                    applicationsContainer.setState({
                                        warnings: []
                                    })
                                    brokerCompaniesContainer.setState({
                                        warnings: []
                                    })
                                    accountsContainer.setState({
                                        warnings: []
                                    })
                                    reportingContainer.setState({
                                        warnings: []
                                    })
                                    registrationsContainer.setState({
                                        warnings: []
                                    })
                        }} />
                    }                    
                    </>
                }
                </Subscribe>
            </div>                   
        )
    }   
}

export default Main