/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"
import { getRecordsPerPage } from "../../constants/Pagination"
import { formatDateWithTime } from "../../helpers/FormatDate"

const componentStyle = css`
    td.check{
        border-top: none !important;
    }

    i{
        cursor: pointer;
        width: 20px;
        text-align: center;
    }

`
class Account extends React.Component{
    
    state = {
        inviteText: "Resend Invite",
    }

    editAccount = async ()=>{
        const { account, accountsContainer } = this.props
        await accountsContainer.getAccount(account.id)
        history.push("/agencies-and-contacts/contact")
    }

    deleteAccount = async ()=>{
        const { account, brokerCompaniesContainer, searchCriteria } = this.props
        let confirm = window.confirm("Are you sure you want to delete " + account.name + "?")
        if(confirm){
            await brokerCompaniesContainer.deleteAccounts([account.id])
            await brokerCompaniesContainer.searchBrokerCompanies(searchCriteria.searchTerm, 
                                                                 searchCriteria.currentPage, 
                                                                 getRecordsPerPage(),
                                                                 searchCriteria.orderBy,
                                                                 searchCriteria.sort[searchCriteria.orderBy] === "desc" ? true : false)
        }
    }

    markAccountForDeletion = (id, e)=>{
        const { accountsContainer } = this.props
        accountsContainer.markAccountForDeletion(id, e.currentTarget.checked)
    }

    resendInvite = async()=>{
        const { account, accountsContainer } = this.props

        await accountsContainer.completeRegistration(account.email)
        
        this.setState({
            inviteText: "Invite Sent"
        })
    }

    render(){
        const { account } = this.props

        return(
            <tr css={componentStyle}>                        
                <td className="check">
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            onChange={(e)=>{this.markAccountForDeletion(account.id, e)}} />
                    </div>
                </td>
                <td>{account.name}</td>
                <td>{account.email}</td>
                <td>{account.admittedProducerCode}</td>
                <td>{account.lastLoginDateTimeUtc ? formatDateWithTime(account.lastLoginDateTimeUtc) : ""}</td>
                <td className="action">
                    <i className="fa fa-pencil-square-o" 
                    onClick={this.editAccount} />
                </td>
                <td className="action">
                    <i className="fa fa-trash"
                    onClick={()=>{this.deleteAccount(account.name, account.id)}} /></td>
                <td>
                    <button className="anchor"
                            onClick={this.resendInvite}>
                        {this.state.inviteText}   
                    </button>
                </td>
            </tr>
        )
    }   
}

export default Account