import { sendPost, sendGet, sendDelete } from "./generic"

export const getEndorsements = ()=> {
    return sendGet("Endorsements/") 
}

export const getEndorsement = (id)=> {
    return sendGet("Endorsements/"+id) 
}

export const addEndorsement = (payload)=> {
    return sendPost("Endorsements", payload) 
}

export const updateEndorsement = (endorsementId, payload)=> {
    return sendPost("Endorsements", payload) 
}

export const deleteEndorsements = (endorsements)=> {
    return sendDelete("Endorsements", endorsements) 
}