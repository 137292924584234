/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import  AdditionalInsured from "./AdditionalInsured"


const componentStyle = css`
    p.up-to-five{
        text-align: left;
        margin-top: -10px;
        margin-bottom: 10px;
        width: 100%;
    }
`
class AdditionalInsureds extends React.Component{

    state = {
        error: false
    }

    addAdditionalInsured = async ()=>{      
        const { applicationsContainer, type } = this.props

        if(type === "Named" && applicationsContainer.state.additionalNamedInsured.size === 5){
            this.setState({
                error: true
            })
            return
        }
        applicationsContainer.addAdditionalInsured(type)
    }

    resetError = ()=>{
        this.setState({
            error: false
        })
    }
        
    render(){
        const { applicationsContainer, type } = this.props
        
        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        {((type === "Additional" && applicationsContainer.state.additionalInsured.size > 0) ||
                          (type === "Named" && applicationsContainer.state.additionalNamedInsured.size > 0)) &&
                            <>
                                <table className="table table-sm table-striped sortable-table">
                                <thead>
                                    <tr>
                                        <th>Name <span className="required">*</span></th>
                                        {type === "Named" &&
                                            <>
                                            <th>Address Line 1 <span className="required">*</span></th>
                                            <th>Address Line 2</th>
                                            <th>City <span className="required">*</span></th>
                                            <th>State <span className="required">*</span></th>
                                            <th>Postal Code <span className="required">*</span></th>
                                            </>
                                        }                                        
                                        <th>Is the proposed Additional {type === "Named" ? " Named" : ""} Insured the owner of an insured tank; the operator of an insured tank; the landlord of a location where an insured tank is located; or, an entity related to any of the preceding groups? <span className="required">*</span></th>
                                        <th className="action"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {type === "Additional" &&
                                        (Array.from(applicationsContainer.state.additionalInsured).map((additionalInsured, index)=>(
                                            <AdditionalInsured key={applicationsContainer.state.additionalInsuredMap.get(additionalInsured).id}
                                                               index={index}
                                                               additionalInsured={additionalInsured}
                                                               type={type}
                                                               applicationsContainer={applicationsContainer}
                                                               resetError={this.resetError} />
                                        )))
                                    }
                                    {type === "Named" &&
                                        (Array.from(applicationsContainer.state.additionalNamedInsured).map((additionalInsured, index)=>(
                                            <AdditionalInsured key={applicationsContainer.state.additionalNamedInsuredMap.get(additionalInsured).id}
                                                               index={index}
                                                               additionalInsured={additionalInsured}
                                                               type={type}
                                                               applicationsContainer={applicationsContainer}
                                                               resetError={this.resetError} />
                                        )))
                                    }                                                                
                                </tbody>
                            </table>
                            <div className="sortable-table-border-bottom-fix" />
                            </>
                        }
                        {this.state.error &&
                            <p className="error up-to-five">
                                Up to 5 entities may be listed as additional named insured or additional insured. Please contact Great American TankAssure representative if additional entities are required.
                            </p>
                        }                        
                        
                        <button className="btn btn-sm btn-primary"
                                onClick={this.addAdditionalInsured}>
                            Add Additional <span className="underlined"><strong>{type === "Named" ? " Named" : ""}</strong></span> Insured
                        </button>
                    </div>                    
                </div>
            </div>
        )
    }   
}

export default AdditionalInsureds