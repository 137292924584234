import { Container } from "unstated"
import * as api from "../api"
import update from "immutability-helper"

class AccountsContainer extends Container {
    
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        isInitalizing: true,
        currentAccount: null,
        roles: [],
        accounts: [],
        editAccount: null,
        searchTerm: "",
        accountsForDeletion: null
    }

    constructor(){
        super()
        this.initialize()
    }

    initialize = async ()=>{
        this.setState({
            accounts: [],
            editAccount: null,
            accountsForDeletion: new Set(),
            isLoading: false,
            isInitalizing: false
        })
    }

    checkResult = async (result)=>{

        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        
        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result?.warnings?.length > 0){
            await this.setState({
                warnings: result.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result.succeeded
        }

        return true
    }
    
    getAccountRoles = async ()=>{
        const result = await api.getAccountRoles()

        if(!this.checkResult(result)){
            return
        }
        
        this.setState({
            roles: result.items
        })
    }

    getCurrentAccount = async ()=>{
        this.setState({
            isLoading: true
        })

        const result = await api.currentAccount()

        if(!this.checkResult(result)){
            return
        }
        
        this.setState({
            currentAccount: result,
            editAccount: result,
            isLoading: false
        })
    }

    updateAccount = async (data)=>{
        this.setState({
            isLoading: true
        })
                
        const payload = {
            brokerCompanyId: data.agencyId ? data.agencyId : data.brokerCompanyId,
            admittedProducerCode: data.admittedProducerCode,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email
        }

        const result = await api.updateAccount(data.id ? data.id : data.userId, payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })

        return result
    }

    updateAccountBrokerCompany = async (accountId, brokerCompanyId) =>{
        this.setState({
            isLoading: true
        })

        const payload = {
            brokerCompanyId: brokerCompanyId
        }

        const result = await api.updateAccount(accountId, payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })
    }

    updatePassword = async (data)=>{
        this.setState({
            isLoading: true
        })

        const payload = {
            password: data.password,
            confirmPassword: data.confirmPassword
        }

        const result = await api.updatePassword(data.id, payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })

        return result
    }

    searchAccountAdmins = async ()=>{

        this.setState({
            isLoading: true
        })

        const result = await api.searchAccountAdmins(1, 1000, "name", false)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            accounts: result.users,
            isLoading: false
        })
    }

    getAccount = async (id)=>{
        const result = await api.getAccount(id)

        if(!this.checkResult(result)){
            return
        }
        
        await this.setState({
            editAccount: result
        })
    }

    addAccount = async (data) =>{
        this.setState({
            isLoading: true
        })

        const payload = {
            admittedProducerCode: data.admittedProducerCode,
            nyftzProducerCode: data.nyftzProducerCode,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            role: data.role
        }

        if(data.brokerCompanyId){
            payload.brokerCompanyId = data.agencyId ? data.agencyId : data.brokerCompanyId
        }

        const result = await api.addAccount(payload)

        if(!this.checkResult(result)){
            await this.initialize()
            return result
        }

        this.setState({
            isLoading: false
        })

        await this.initialize()
        return result
    }

    approveAccount = async (userId, email, approve, companyRegistrationId)=>{
        this.setState({
            isLoading: true
        })


        let payload = {
            approve: approve,
            companyRegistrationId: companyRegistrationId
        }

        let result = await api.approveAccount(userId, payload)


        if(approve){
            payload ={
                email: email
            }
            
            result = await api.completeRegistration(payload)
            
            if(!this.checkResult(result)){
                return
            }
            
            this.setState({
                isLoading: false
            })
        }
        

        return result
    }

    completeRegistration = async (email)=>{
        this.setState({
            isLoading: true
        })
        
        const payload ={
            email: email
        }
        
        const result = await api.completeRegistration(payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })
    }

    updateAccount = async (data)=>{
        this.setState({
            isLoading: true
        })

        const payload = {
            admittedProducerCode: data.admittedProducerCode,
            nyftzProducerCode: data.nyftzProducerCode,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            role: data.role
        }

        if(data.brokerCompanyId || data.agencyId){
            payload.brokerCompanyId = data.agencyId ? data.agencyId : data.brokerCompanyId
        }

        const result = await api.updateAccount(data.id ? data.id : data.userId, payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })

        await this.initialize()

        return result
    }

    deleteAccounts = async (accounts) =>{

        if(!accounts.length){
            return
        }

        this.setState({
            isLoading: true
        })

        const result = await api.deleteAccounts(accounts)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })
    }

    markAccountForDeletion = async (id, checked)=>{
        const accountsForDeletion = this.state.accountsForDeletion
        let newAccountsForDeletion
        if(checked){
            newAccountsForDeletion = update(accountsForDeletion, {$add: [id]})
        }else{
            newAccountsForDeletion = update(accountsForDeletion, {$remove: [id]})
        }

        await this.setState({
            accountsForDeletion: newAccountsForDeletion
        })        
    }

    deleteSelectedAccounts = async ()=>{
        this.setState({
            isLoading: true
        })

        await this.deleteAccounts(Array.from(this.state.accountsForDeletion))
       
        await this.initialize()

        await this.searchAccountAdmins()

        this.setState({
            isLoading: false
        })
    }
}

export default AccountsContainer