/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"

const componentStyle = css`
    td.check{
        border-top: none !important;
    }

    i{
        cursor: pointer;
    }
`
class Endorsement extends React.Component{


    editEndorsement = async ()=>{
        const { endorsementsContainer, endorsement} = this.props

        await endorsementsContainer.getEndorsement(endorsement.id)
        history.push("/endorsements/endorsement")
    }

    deleteEndorsement = async (name, id)=>{
        const { endorsementsContainer } = this.props
        let confirm = window.confirm("Are you sure you want to delete " + name + "?")
        if(confirm){
           await endorsementsContainer.deleteEndorsements([id])
           await endorsementsContainer.getEndorsements()
        }
    }

    render(){
        const { endorsement } = this.props

        return(
            <tr css={componentStyle}>
                <td>{endorsement.displayOrder}</td>
                <td>{endorsement.name}</td>
                <td>{endorsement.type}</td>
                <td>{endorsement.number}</td>
                <td>{endorsement.edition}</td>
                <td className="action">
                    <i className="fa fa-pencil-square-o" 
                    onClick={ ()=>{
                        this.editEndorsement(endorsement.id)
                    }} />
                </td>
                <td className="action">
                    <i className="fa fa-trash"
                    onClick={()=>{this.deleteEndorsement(endorsement.name, endorsement.id)}} />
                </td>
            </tr>
        )
    }   
}

export default Endorsement