import { sendPost, sendGet, sendDelete } from "./generic"

export const approveAccount = (agencyId, payload)=> {
    return sendPost("Account/" + agencyId + "/Approve", payload) 
}

export const getAccount = (id)=> {
    return sendGet("Account/"+id) 
}

export const addAccount = (payload)=> {
    return sendPost("Account", payload) 
}

export const updateAccount = (accountId, payload)=> {
    return sendPost("Account/" + accountId, payload) 
}

export const deleteAccounts = (accounts)=> {
    return sendDelete("Account", accounts) 
}

export const currentAccount = ()=> {
    return sendGet("Account/Current") 
}

export const updatePassword = (accountId, payload)=> {
    return sendPost("Account/" + accountId + "/UpdatePassword", payload) 
}

export const searchAccountAdmins = (page, numberOfRecords, orderBy, descending)=>{
    return sendGet("Account/Admins/"+page+"/"+numberOfRecords+"/"+orderBy+"/"+descending )
}

export const getAccountRoles = ()=>{
    return sendGet("Account/Roles")
}

export const completeRegistration = (payload)=> {
    return sendPost("Account/CompleteRegistration", payload)
}

export const resend2faToken = ()=>{
    return sendGet("Account/Send2faToken")
}