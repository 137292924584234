import { Container } from "unstated";
import * as api from "../api";

class ReportingContainer extends Container {
  state = {
    isLoading: false,
    warnings: [],
    warningHeader: "",
    warningFooter: "",
    reportingTypes: [],
    applicationStatus: [],
  };

  checkResult = async (result) => {
    if (!result) {
      await this.setState({
        warnings: "There was an unexpected problem. Please try again.",
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return false;
    }

    if (!result?.succeeded) {
      await this.setState({
        warnings:
          result?.warnings && result?.warnings?.length > 0
            ? result.warnings
            : ["There was an unexpected problem. Please try again."],
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return false;
    }
    if (result?.warnings?.length > 0) {
      await this.setState({
        warnings: result.warnings,
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return result.succeeded;
    }

    return true;
  };

  getReportingData = async (payload) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.postReportingData(payload);

    if (!this.checkResult(result)) {
      return;
    }
  };

  exportReportingData = async (payload) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.exportReportingData(payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
    
    return result
  };

  getReportingTypes = async (payload) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getReportingTypes(payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      reportingTypes: result?.items,
      isLoading: false,
    });
  };

  getApplicationsStatus = async (payload) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getApplicationsStatus(payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      applicationStatus: result?.items,
      isLoading: false,
    });
  };
}

export default ReportingContainer;
