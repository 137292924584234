/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"
import { formatDate } from "../../helpers/FormatDate"

const componentStyle = css`
`
class BehalfOfApplication extends React.Component{ 

    state = {
        id: ""
    }

    componentDidMount = ()=>{
        const { application } = this.props
        this.setState({
            id: application.id
        })
    }

    continueApplication = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.continueApplication(this.state.id)
    }

    getApplicationPDF = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.getApplicationPDF(this.state.id)
    }

    bindApplication = async ()=>{
        const { applicationsContainer } = this.props

        await applicationsContainer.initialize()
        
        await applicationsContainer.setState({
            currentApplicationId: this.state.id
        })

        history.push("application/bind")
    }

    getComments = async ()=>{
        const { applicationsContainer, toggleComments } = this.props
        
        toggleComments()

        applicationsContainer.getComments(this.state.id)
    }
    cloneApplication = async ()=>{
        const { applicationsContainer,getApplications } = this.props
        await applicationsContainer.cloneApplication(this.state.id)
        await getApplications()
    }

    viewApplication = ()=>{
        const { viewApplication } = this.props
        viewApplication(this.state.id)
    }
   
        
    render(){
        const { application } = this.props

        return(            
            <tr css={componentStyle}>
                <td>{application.friendlyId}</td>
                <td>{application.namedInsured}</td>
                <td>{formatDate(application.dateStartedUtc)}</td>
                <td>{formatDate(application.dateSubmittedUtc)}</td>
                <td>{application.version}</td>
                <td>{application.status}</td>
                <td>{application.transaction}</td>
                <td><button className="anchor" onClick={this.viewApplication}>View</button></td>
                <td>
                    <button className="anchor"
                            onClick={this.getComments}>
                        Comments
                    </button>
                </td>
                <td>
                    {(application.status === "Incomplete" ||
                     application.status === "Requires additional information") &&
                        <button className="anchor"
                                onClick={this.continueApplication}>
                            Continue
                        </button>
                    }                    
                </td>
                <td>
                    {application.status === "Approved" &&
                        <button className="anchor"
                                onClick={this.getApplicationPDF}>
                            View Quote
                        </button>
                    }
                </td>
                <td>
                    {application.status === "Approved" &&
                        <button className="anchor"
                                onClick={this.bindApplication}>
                            Bind
                        </button>
                    }
                </td>
                <td>{(application.status === "Approved" ||
                      application.status === "Approved to Bind") &&
                      <button className="anchor" onClick={this.cloneApplication}>
                         Clone
                      </button>
                    }
                </td>
            </tr>
        )
    }   
}

export default BehalfOfApplication