/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Registrations from "./Registrations"
import Agencies from "./Agencies"


const componentStyle = css`

`
class AgenciesAndContacts extends React.Component{

    componentDidMount(){
        window.scrollTo(0,0)
        
        const { brokerCompaniesContainer } = this.props
        brokerCompaniesContainer.initialize()
    }
            
    render(){
        const { brokerCompaniesContainer, accountsContainer, registrationsContainer } = this.props
        return(
    
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h2>Agencies and Contacts</h2>
                    </div>
                </div>

                <Registrations brokerCompaniesContainer={brokerCompaniesContainer}
                               accountsContainer={accountsContainer}
                               registrationsContainer={registrationsContainer} />

                <Agencies brokerCompaniesContainer={brokerCompaniesContainer}
                          accountsContainer={accountsContainer} />

            </div>
            
        )
    }   
}

export default AgenciesAndContacts