/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { getRecordsPerPage } from "../../constants/Pagination"
import BehalfOfApplication from "./BehalfOfApplication"
import Pagination from "../../components/Pagination"
import TableSort from "../../components/TableSort"
import ReviewApplication from "./ReviewApplication"
const componentStyle = css`
    margin-top: 65px;

    td.label {
        width: 250px;
        padding-right: 20px;
        text-align: right;
    }
`
class BehalfOfApplications extends React.Component{ 

    state = {
        totalRecords: 0,
        recordsPerPage: getRecordsPerPage(),
        totalPages: 0,
        currentPage: 1,
        orderBy: "NamedInsuredName",
        sort: {
            FriendlyId: null,
            NamedInsured: "asc",
            CustomerNumber: null,
            DateStarted: null,
            DateSubmitted: null,
            Version: null,
            Status: null,
            Transaction: null
        },
        viewApplicationVisible: false
    }

    componentDidMount = ()=>{        
        this.getApplications()
    }

    getApplications = async()=>{
        const { applicationsContainer } = this.props
        const totalRecords = await applicationsContainer.getApplications(this.state.currentPage,
                                                                         getRecordsPerPage(),
                                                                         this.state.orderBy,
                                                                         this.state.sort[this.state.orderBy] === "asc" ? true : false)
        await this.setState({
            totalRecords: totalRecords,
            totalPages: Math.ceil(totalRecords/this.state.recordsPerPage)
        })
    }

    sortTable = async (orderBy, direction)=>{
        let tempSort = {}
        
        for (const key of Object.keys(this.state.sort)) {
            tempSort[key] = null;
        }
        
        if(!direction){
            tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc"
        }else{
            tempSort[orderBy] = direction
        }    
                
        await this.setState({
            orderBy: orderBy,
            sort: tempSort,
            currentPage: 1
        })

        this.getApplications()
    }

    changePage = async (page)=>{
        await this.setState({
            currentPage: page
        })

        this.getApplications()
    }

    toggleViewApplication = ()=>{
        this.setState({
            viewApplicationVisible: !this.state.viewApplicationVisible
        })
    }

    viewApplication = async (id)=>{
        const { applicationsContainer } = this.props

        this.toggleViewApplication();

        await applicationsContainer.setState({
            currentApplicationId: id,
            currentApplication: null,
            coverages: [],
            locations: [],
            abovegroundStorageTanks: [],
            undergroundStorageTanks: [],
            requirementQuestions: [],
            additionalNamedInsured: [],
            additionalInsured: [],
            total: ""
        })


        await applicationsContainer.viewApplication(id)
        await applicationsContainer.setState({
          isLoading: false,
        });  
    }
    render(){
        const { applicationsContainer, toggleComments } = this.props
        const curApp = applicationsContainer.state.currentApplication
        return (
          <div css={componentStyle}>
            <h3>Applications on Behalf of Agencies</h3>

            <div className="row">
              <div className="col">
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("FriendlyId")}
                          direction={this.state.sort.FriendlyId}
                        >
                          Id Number
                        </TableSort>
                      </th>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("NamedInsured")}
                          direction={this.state.sort.NamedInsured}
                        >
                          First Name Insured
                        </TableSort>
                      </th>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("DateStarted")}
                          direction={this.state.sort.DateStarted}
                        >
                          Date Started
                        </TableSort>
                      </th>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("DateSubmitted")}
                          direction={this.state.sort.DateSubmitted}
                        >
                          Date Submitted
                        </TableSort>
                      </th>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("Version")}
                          direction={this.state.sort.Version}
                        >
                          Version
                        </TableSort>
                      </th>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("Status")}
                          direction={this.state.sort.Status}
                        >
                          Status
                        </TableSort>
                      </th>
                      <th>
                        <TableSort
                          onClick={() => this.sortTable("Transaction")}
                          direction={this.state.sort.Transaction}
                        >
                          Renewals vs New Business
                        </TableSort>
                      </th>
                      <th>Review</th>
                      <th>View Comments</th>
                      <th>Continue Application</th>
                      <th>View Quote</th>
                      <th>Bind Your Quote</th>
                      <th>Clone Application</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicationsContainer.state.applications.map(
                      (application) => (
                        <BehalfOfApplication
                          key={application.id}
                          applicationsContainer={applicationsContainer}
                          application={application}
                          toggleComments={toggleComments}
                          getApplications={this.getApplications}
                          viewApplication={this.viewApplication}
                        />
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {this.state.totalRecords > 1 && (
              <div className="row">
                <div className="col-sm-12">
                  <Pagination
                    totalPages={this.state.totalPages}
                    currentPage={this.state.currentPage}
                    changePage={this.changePage}
                  />
                </div>
              </div>
            )}

            {this.state.viewApplicationVisible && (

                <ReviewApplication 
                    applicationsContainer={applicationsContainer}
                    curApp={curApp}
                    toggleViewApplication={this.toggleViewApplication}
                 />
              
            )}
          </div>
        );
    }   
}

export default BehalfOfApplications