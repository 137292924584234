/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
const componentStyle = css`
  cursor: pointer;
  display: inline-block;
  padding-top: 5px;

  span {
    vertical-align: top;
    line-height: 17px;
  }

  div {
    display: inline-block;
    margin-left: 5px;
  }

  i {
    display: block;
    line-height: 3px;
    color: #aaa;
  }

  i.active {
    color: #666;
  }
`;
class TableSort extends React.Component {
  render() {
    const { direction, onClick } = this.props;

    return (
      <div css={componentStyle} onClick={onClick}>
        <span>{this.props.children}</span>
        <div>
          <i
            className={
              "fa fa-sort-asc" + (direction === "asc" ? " active" : "")
            }
          />
          <i
            className={
              "fa fa-sort-desc" + (direction === "desc" ? " active" : "")
            }
          />
        </div>
      </div>
    );
  }
}

export default TableSort;
