/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { getRecordsPerPage } from "../../constants/Pagination"
import Policy from "./Policy"
import Pagination from "../../components/Pagination"
import TableSort from "../../components/TableSort"
import ReviewApplication from "./ReviewApplication"

const componentStyle = css`
    margin-top: 15px;    

    table{
        margin-top: 10px;
    }

    td.label {
        width: 250px;
        padding-right: 20px;
        text-align: right;
    }
`
class Policies extends React.Component{ 

    state = {
        totalRecords: 0,
        recordsPerPage: getRecordsPerPage(),
        totalPages: 0,
        currentPage: 1,
        orderBy: "NamedInsuredName",
        sort: {
            FriendlyId: null,
            NamedInsured: "asc",
            PolicyNumber: null,
            DateEffective: null,
            Status: null
        },
        latestPolicies: {},
        viewApplicationVisible: false
    }

    componentDidMount = ()=>{        
        this.getPolicies()
    }

    getPolicies = async()=>{
        const { applicationsContainer } = this.props
        const totalRecords = await applicationsContainer.getPolicies(this.state.currentPage,
                                                                     getRecordsPerPage(),
                                                                     this.state.orderBy,
                                                                     this.state.sort[this.state.orderBy] === "asc" ? true : false)
        await this.setState({
            totalRecords: totalRecords,
            totalPages: Math.ceil(totalRecords / this.state.recordsPerPage)
        }, this.calculateLatestPolicies)
    }

    calculateLatestPolicies = () => {
        const { policies } = this.props.applicationsContainer.state;
        const latestPolicies = {};
        policies.forEach(policy => {
            const key = policy.policyNumber;
            if (!latestPolicies[key]) {
                latestPolicies[key] = policy;
            } else {
                const latestPolicy = latestPolicies[key];
                if (policy.mod > latestPolicy.mod || (policy.mod === latestPolicy.mod && policy.version > latestPolicy.version)) {
                    latestPolicies[key] = policy;
                }
            }
        });
        this.setState({ latestPolicies });
    };

    sortTable = async (orderBy, direction)=>{
        let tempSort = {}
        
        for (const key of Object.keys(this.state.sort)) {
            tempSort[key] = null;
        }
        
        if(!direction){
            tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc"
        }else{
            tempSort[orderBy] = direction
        }    
                
        await this.setState({
            orderBy: orderBy,
            sort: tempSort,
            currentPage: 1
        })

        this.getPolicies()
    }

    changePage = async (page)=>{
        await this.setState({
            currentPage: page
        })

        this.getPolicies()
    }

    toggleViewApplication = ()=>{
        this.setState({
            viewApplicationVisible: !this.state.viewApplicationVisible
        })
    }

    viewApplication = async (id)=>{
        const { applicationsContainer } = this.props

        this.toggleViewApplication();

        await applicationsContainer.setState({
            currentApplicationId: id,
            currentApplication: null,
            coverages: [],
            locations: [],
            abovegroundStorageTanks: [],
            undergroundStorageTanks: [],
            requirementQuestions: [],
            additionalNamedInsured: [],
            additionalInsured: [],
            total: ""
        })


        await applicationsContainer.viewApplication(id)
        await applicationsContainer.setState({
          isLoading: false,
        });  
    }
        
    render(){
        const { applicationsContainer } = this.props
        const { latestPolicies } = this.state;
        const curApp = applicationsContainer.state.currentApplication
        return(
            <div css={componentStyle}>
                <h3>My Policies</h3>

                <table className="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>
                                <TableSort onClick={()=>this.sortTable("FriendlyId")}
                                            direction={this.state.sort.FriendlyId}>
                                    ID Number
                                </TableSort>
                            </th>
                            <th>
                                <TableSort onClick={()=>this.sortTable("NamedInsured")}
                                            direction={this.state.sort.NamedInsured}>
                                    First Name Insured
                                </TableSort>
                            </th>
                            <th>
                                <TableSort onClick={()=>this.sortTable("PolicyNumber")}
                                            direction={this.state.sort.PolicyNumber}>
                                    Policy Number
                                </TableSort>
                            </th>
                            <th>
                                <TableSort onClick={()=>this.sortTable("DateEffective")}
                                            direction={this.state.sort.DateEffective}>
                                    Policy Period
                                </TableSort>
                            </th>
                            <th>
                                <TableSort onClick={()=>this.sortTable("Status")}
                                            direction={this.state.sort.Status}>
                                    Status
                                </TableSort>
                            </th>
                            <th>Review</th>
                            <th>Download Your Policy</th>
                            <th>Renew Your Policy</th>
                        </tr>
                    </thead>
                    <tbody>
                    {applicationsContainer.state.policies.map(policy=>(
                        <Policy key={policy.id}
                                     applicationsContainer={applicationsContainer}
                                     policy={policy} 
                                     isLatest={latestPolicies[policy.policyNumber]?.id === policy.id}
                                     viewApplication={this.viewApplication}
                                     />
                    ))}
                    </tbody>
                </table>
                {(this.state.totalRecords > 1) &&
                    <div className="row">
                        <div className="col-sm-12">
                            <Pagination totalPages={this.state.totalPages}
                                        currentPage={this.state.currentPage}
                                        changePage={this.changePage} />
                        </div>
                    </div>
                }  

                {this.state.viewApplicationVisible && (

                <ReviewApplication
                    applicationsContainer={applicationsContainer}
                    curApp={curApp}
                    toggleViewApplication={this.toggleViewApplication}
                />

                )}
              
            </div>
        )
    }   
}

export default Policies