import { Container } from "unstated"
import * as api from "../api"

class endorsementsContainer extends Container {
    
    state = {
        isLoading: false,
        endorsements: [],
        editEndorsement: null
    }

    checkResult = async (result)=>{
        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result?.warnings?.length > 0){
            await this.setState({
                warnings: result.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result.succeeded
        }

        return true
    }

    getEndorsements = async ()=>{
        const result = await api.getEndorsements()

        if(!this.checkResult(result)){
            return
        }

        this.setState({
           endorsements: result
        })
    }

    getEndorsement = async (id)=>{
        const result = await api.getEndorsement(id)

        if(!this.checkResult(result)){
            return
        }
        
        await this.setState({
            editEndorsement: result
        })
    }

    updateEndorsement = async (data)=>{
        this.setState({
            isLoading: true
        })

        const payload = {
            id: this.state.editEndorsement.id,
            name: data.name,
            number: data.number,
            appliesTo: data.appliesTo,
            edition: data.edition,
            type: data.type,
            tankType: data.tankType,
            logicalOperator: data.logicalOperator,
            tagRule: data.tagRule,
            statesCsv: data.statesCsv,
            footerStatesCsv: data.footerStatesCsv,
            displayOrder: data.displayOrder,
            xml: data.xml,
            error: data.error
        }

        const result = await api.updateEndorsement(data.id, payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })

        return result
    }

    addEndorsement = async (data) =>{
        this.setState({
            isLoading: true
        })

        const payload = {
            name: data.name,
            number: data.number,
            appliesTo: data.appliesTo,
            edition: data.edition,
            type: data.type,
            tankType: data.tankType,
            logicalOperator: data.logicalOperator,
            tagRule: data.tagRule,
            statesCsv: data.statesCsv,
            footerStatesCsv: data.footerStatesCsv,
            displayOrder: data.displayOrder,
            xml: data.xml,
            error: data.error
        }

        const result = await api.addEndorsement(payload)

        this.setState({
            isLoading: false
        })

        await this.getEndorsements()

        return result
    }

    deleteEndorsements = async (endorsements) =>{
        this.setState({
            isLoading: true
        })
        
        await api.deleteEndorsements(endorsements)

        this.setState({
            isLoading: false
        })
    }
}

export default endorsementsContainer