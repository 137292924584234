import { Container } from "unstated"
import * as api from "../api"
import history from "../components/History"

class RegistrationsContainer extends Container {
    
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        registrations: [],
        registration: null
    }

    initialize = async ()=>{
    }

    checkResult = async (result)=>{
        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result?.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result?.warnings?.length > 0){
            await this.setState({
                warnings: result.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result.succeeded
        }

        return true
    }

    getRegistrations = async (page, numberOfRecords, orderBy, descending)=>{     
        this.setState({
            isLoading: true
        })

        const result = await api.getRegistrations(page, numberOfRecords, orderBy, descending)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            registrations: result.registrations
        })

        this.setState({
            isLoading: false
        })

        return result.totalRecords
    }


    editRegistration = async (brokerCompanyId, companyRegistrationId)=>{
        this.setState({
            isLoading: true,
            agencyId: brokerCompanyId
        })

        const result = await api.getRegistration(brokerCompanyId, companyRegistrationId)

        if(!this.checkResult(result)){
            return
        }
       
        await this.setState({
            registration: result,
            isLoading: false
        })

        history.push("/edit-registration")
    }

    register = async (data)=>{
        
        const payload = {
            agencyName: data.agencyName,
            typeOfEntity: data.typeOfEntity,
            admittedProducerCode: data.admittedProducerCode,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            mainPhone: data.mainPhone,
            website: data.website,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            acceptedTermsAndConditions: data.acceptedTermsAndConditions,
            commission: data.commission ? data.commission : 20
        }
        
        
        const result = await api.register(payload)

        if(!this.checkResult(result)){
            return
        }

        return result
    }
}

export default RegistrationsContainer