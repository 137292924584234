/** @jsxImportSource @emotion/react */
import React from "react"

class Logout extends React.Component{

    componentDidMount(){
        this.props.authenticationContainer.logout()
    }
    
    render(){
        return(
            <></>
        )
    }

}

export default Logout